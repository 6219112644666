<template>
  <div class="mt-5 welcome-tour">
    <div class="container">
      <div class="row">
        <div class="col d-flex justify-content-end">
          <button
            type="button"
            class="btn btn-secondary"
            @click.prevent="presentCloseAlert"
          >
            Close
            <i class="fa fa-solid fa-xmark"></i>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <progress-component
            :min-value="0"
            :max-value="100"
            :current-value="currentProgressValue"
            :progress-label="`${welcomeTour.count} von ${totalPages}`"
          />
        </div>
      </div>
    </div>
    <div class="container mt-4">
      <router-view :key="router" />
    </div>
  </div>
</template>

<script setup>
import { computed, ref, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ProgressComponent from "@client/components/ProgressComponent.vue";
import authHeader from "@/auth.header.js";
import { API_URL } from "@/conf.js";

const store = useStore();
const router = useRouter();
const { proxy } = getCurrentInstance();
const totalPages = ref(6);
const welcomeTour = computed(() => store.state.welcomeTour);

const currentProgressValue = computed(() => {
  return (welcomeTour.value.count / totalPages.value) * 100;
});

const presentCloseAlert = async () => {
  await proxy
    .$swal({
      titleText: "Willkommenstour schließen",
      icon: "warning",
      input: "checkbox",
      inputValue: 0,
      inputPlaceholder: `Zeig mir das nicht noch einmal!`,
      showConfirmButton: true,
      confirmButtonColor: "#891E35",
      showCancelButton: true,
      confirmButtonText: "Bestätigen",
      cancelButtonText: "Stornieren",
    })
    .then(async (willClose) => {
      if (willClose.isConfirmed) {
        const { value: accept } = willClose;
        if (accept) {
          await axios.patch(
            `${API_URL}user/hide_welcome_tour`,
            {},
            { headers: await authHeader() },
          );
        }
        // reset the welcometour store state
        await store.commit("updateWelcomeMode", {
          isWelcomeMode: false,
          count: 0,
        });
        router.push({ name: "dashboard" });
      }
    })
    .catch((error) => console.log(error));
};
</script>
