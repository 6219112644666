<script>
export default {
  name: "PremiumAdComponent",
  data() {
    return {
      adToShow: false,
    };
  },
  mounted() {
    const cookieValue = document.cookie
      .split("; ")
      .find((row) => row.startsWith("native_app="))
      ?.split("=")[1];

    this.adToShow = cookieValue === "yes";
    /*if (cookieValue === "yes") {
            this.showBrowser = false
            //document.getElementById("showBrowser").style.display = "none";
        } else {
            this.showBrowser = false
            //document.getElementById("showMobile").style.display = "none";
        }*/
  },
};
</script>

<template>
  <div class="premium-ad-box text-center" id="showBrowser" v-if="adToShow === false">
    <div class="title">
      <p>
        Deine schnellste Route wurde berechnet: Mit PharmaNavi Premium kannst du
        jetzt auf alle Inhalte zugreifen und kommst so noch schneller ans Ziel.
      </p>
      <router-link class="btn btn-primary" :to="{ name: 'profile.subscribe', params: { pkg: 8 } }">Upgrade
        buchen</router-link>
    </div>
  </div>

  <div class="premium-ad-box text-center" id="showMobile" v-else>
    <div class="title">
      <p>Upgrade in der Desktop-Version buchbar</p>
    </div>
  </div>
</template>

<style scoped></style>
