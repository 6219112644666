<script>
import { defineComponent } from "vue";
import authHeader from "@/auth.header.js";
import { API_URL } from "@/conf.js";

export default defineComponent({
  name: "LearningUnits",
  data() {
    return {
      learning_units: [],
    };
  },
  mounted() {
    this.loadLearningUnits();
  },
  methods: {
    loadLearningUnits() {
      const headers = authHeader();
      headers["content-type"] = "application/json";
      axios
        .get(
          `${API_URL}learning_unit/get_learning_units_by_topic/${this.$route.params.topic}`,
          {
            headers: headers,
          },
        )
        .then((response) => {
          if (response.status === 200) {
            this.learning_units = response.data;
          } else {
            this.$swal("Error", response.data.message, "error");
          }
        })
        .catch((error) => {
          this.$swal("Error", "An Error Occurred. Try again later!", "error");
        });
    },
  },
});
</script>

<template>
  <div class="container pt-3 search-container">
    <div class="row">
      <div class="col-12 col-sm-12 offset-md-3 col-md-6">
        <h1>Lernheiten</h1>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-sm-12 mx-auto">
        <router-link
          class="search-link"
          v-for="learning_unit in learning_units"
          :key="'learning_unit' + learning_unit.id"
          :to="{
            name: 'LearningUnit',
            params: { id: learning_unit.id },
          }"
        >
          {{ learning_unit.wording }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
