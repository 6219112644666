<template>
  <div id="sidebar" class="sidebar" ref="sidebar">
    <div class="nav-profile">
      <div class="img-container mb-4">
        <img class="img-circle" :src="profile.avatar !== null &&
            profile.avatar.trim().length > 0 &&
            profile.avatar !== '0'
            ? `https://assets.pharmanavi.de/assets/uploads/avatars/${profile.avatar}`
            : 'https://assets.pharmanavi.de/assets/uploads/Logo_2023/favicon-modified.png'
          " alt="Profile" />
        <img v-if="
          profile.user_verification !== null &&
          profile.user_verification.status === 1
        " src="https://assets.pharmanavi.de/assets/uploads/app_icons/icons/Verifikation.svg" class="profile-badge"
          alt="Badge" />
      </div>
      <h1 class="nav-username" style="">
        Hey
        <span>{{
          (profile.username !== null && profile.username.trim().length) > 0
            ? profile.username
            : "User"
        }}</span>
      </h1>
      <p class="greeting-text">Schön, dass du da bist!</p>
    </div>
    <div class="nav-menu">
      <router-link :class="{ active: pageName === 'dashboard' }" :to="{ name: 'dashboard' }">
        <img src="https://assets.pharmanavi.de/assets/uploads/app_icons/menu_bar/Home.svg" alt="" />
        Home
      </router-link>
      <router-link :class="{ active: pageName === 'search' }" :to="{ name: 'search' }">
        <img src="https://assets.pharmanavi.de/assets/uploads/app_icons/menu_bar/Suche.svg" alt="" />
        Wissen
      </router-link>
      <a href="/topics" v-on:click.prevent.stop="routeToTopics" :class="{ active: pageName === 'topics' }">
        <img src="https://assets.pharmanavi.de/assets/uploads/app_icons/menu_bar/Fach.svg" alt="" />
        Fächer</a>
      <!--      <router-link
        :to="{ name: 'topics' }"
        :class="{active: pageName ==='topics'}"
      >
        <img :src="require('@icons/menu_bar/Fach.svg').default" alt="">
        Fächer
      </router-link>-->
      <router-link :to="{ name: 'forum' }" :class="{ active: pageName === 'forum' }">
        <img src="https://assets.pharmanavi.de/assets/uploads/app_icons/menu_bar/Forum.svg" alt="" />
        Forum
      </router-link>
      <router-link :to="{ name: 'profile' }" :class="{ active: pageName === 'profile' }">
        <img src="https://assets.pharmanavi.de/assets/uploads/app_icons/menu_bar/Account.svg" alt="" />
        Profil
      </router-link>
    </div>
    <div class="nav-links-container">
      <div class="nav-links">
        <router-link :class="{ active: pageName === 'community' }" :to="{ name: 'community' }">
          <span class="link-text"> On demand </span>
          &nbsp;
          <span class="highlighted-icon">
            <i class="fa-solid fa-arrow-right"></i>
          </span>
        </router-link>
        <router-link :class="{ active: pageName === 'topics.selected' }" :to="{ name: 'topics.pinned' }">
          <span class="link-text"> Gemerkte Fächer </span>
          &nbsp;
          <span class="highlighted-icon">
            <i class="fa-solid fa-arrow-right"></i>
          </span>
        </router-link>
      </div>
      <div class="nav-links">
        <router-link :class="{ active: pageName === 'faq' }" :to="{ name: 'faq' }">
          <span class="link-text"> FAQ </span>
        </router-link>
      </div>
      <div class="nav-icons d-flex flex-row">
        <div class="icon-link">
          <a href="https://www.instagram.com/pharmanavi/" target="_blank">
            <img src="https://assets.pharmanavi.de/assets/uploads/instagram-icon.png" alt="Instagram" />
          </a>
        </div>
        <div class="icon-link">
          <a href="https://www.facebook.com/pharmanavi/" target="_blank">
            <img src="https://assets.pharmanavi.de/assets/uploads/facebook-icon.png" alt="Facebook" />
          </a>
        </div>
        <div class="icon-link">
          <a href="https://open.spotify.com/show/47oKq6Cw7ulV6VIyoBzMSD" target="_blank">
            <img src="https://assets.pharmanavi.de/assets/uploads/spotify-icon.png" alt="Spotify" />
          </a>
        </div>
      </div>
      <div class="nav-links mt-2 mb-5">
        <a href="#" @click.prevent="logout">
          <i class="fa-solid fa-arrow-right"></i> &nbsp;
          <span class="link-text"> Abmelden </span>
        </a>
      </div>
      <div class="info-links text-center">
        <a href="https://www.pharmanavi.de/agb/" target="_blank">AGB</a>
        &nbsp;
        <a href="https://www.pharmanavi.de/datenschutz/" target="_blank">Datenschutz</a>
        &nbsp; <a href="/?cmpscreencustom">Cookies</a> &nbsp;
        <a href="https://www.pharmanavi.de/widerrufsbelehrung/" target="_blank">Widerrufsbelehrung</a>
        &nbsp;
        <a href="https://www.pharmanavi.de/impressum/" target="_blank">Impressum</a>
        &nbsp;
      </div>
    </div>
  </div>
</template>

<script>
import useLogout from "@client/composables/useLogout";

export default {
  name: "side-nav-component",
  setup() {
    const logout = useLogout();

    return {
      logout,
    };
  },
  computed: {
    pageName() {
      if (!this.$route.name) return "";
      const delimeter = ".";
      const parts = this.$route.name.split(delimeter);
      return parts[0];
    },
    profile() {
      return this.$store.state.user;
    },
  },
  methods: {
    routeToTopics() {
      if (this.$route.name === "topics") {
        this.$emit("load-main-topics");
      } else {
        this.$router.push({ name: "topics" });
      }
    },
  },
};
</script>
