<template>
  <div class="notification-box text-center">
    <div class="title">
      <h5 class="sub-heading-4">Auf geht's ins neue Semester!</h5>
    </div>
    <div class="content">
      <p>
        Trage im Profil direkt dein neues Semester ein, dann können wir dir
        deine neuen Fächer anzeigen. Außerdem wurde deine Verifikation gelöscht
        und du müsstest sie erneuern.
      </p>
      <router-link class="btn btn-primary" :to="{ name: 'profile' }">
        Profil
        <i class="fa fa-arrow-right"></i>
      </router-link>
    </div>
  </div>
</template>

<script setup></script>
