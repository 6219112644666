<template>
<div class="container">
    <div class="row archive">
        <div class="col-12">
            <h2 v-if="archive.title !== null">{{archive.title}}</h2>
            <p v-html="archive.description"></p>
            <a v-if="archive.file != null && archive.file.length > 0" v-bind:href="'https://assets.pharmanavi.de/assets/uploads/Streaming/' + archive.file" class="btn btn-primary mb10 download-link" v-bind:download="archive.title" target="_blank">
                Übungszettel
                <i class="fa fa-download"></i>
            </a>
            <a v-if="archive.presentation != null && archive.presentation.length > 0" v-bind:href="'https://assets.pharmanavi.de/assets/uploads/Streaming/' + archive.presentation" class="btn btn-primary mb10 download-link" v-bind:download="archive.title + '_presentation'" target="_blank">
                Präsentation
                <i class="fa fa-download"></i>
            </a>
            <br/><br/>
            <div class="archive-video-wrapper">
                <div v-if="archive.vimeo_video_id != null" class="video-container mb30">
                    <iframe v-bind:src="archive.src" width="640" height="399" frameborder="0" ref="vimeoIframe" id="vimeo-iframe"
                            allow="autoplay; fullscreen" allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;" showinfo="false"></iframe>
                </div>
                <div v-if="showVideo === false" class="basic-video-text">
                    <h3 class="text-center text-secondary">Gefällt dir das Video?</h3>
                    <p class="text-secondary">Mit PharmaNavi-Premium kannst du alle Videos in voller Länge anschauen. Buche direkt in den Profileinstellungen und nutze alle Vorteile von PharmaNavi.</p>
                </div>
            </div>

        </div>
    </div>
</div>
</template>

<script>
import {API_URL} from "@/conf.js";
import authHeader from "@/auth.header.js";
import Player from '@vimeo/player';
import {mapState} from "vuex";

export default {
    name: "Archive",
    data() {
        return {
            archive: {},
            showVideo: true,
        }
    },
    beforeMount() {
        this.loadArchive();
    },
    mounted() {
        let content = document.getElementById("app");
        // Now scan for media
        window._paq.push(['MediaAnalytics::scanForMedia', content]);
    },
    computed: {
        ...mapState(['isAuthenticated', 'user'])
    },
    methods: {
        loadArchive() {
            axios.get(`${API_URL}archives/${this.$route.params.id}/show`, {'headers': authHeader()})
                .then(response => {
                    if (response.status === 200) {
                        //this.archive = response.data.archive;
                        this.fetchFromVimeoApi(response.data.archive);
                        this.$emit("navigation-sub-heading", response.data.archive.title);
                    }
                })
                .catch(error => {
                    console.log(error)
                });
        },
        async fetchFromVimeoApi(archive, duration = 0) {
            if (this.user.package !== null && this.user.package.is_default !== 1) {
                this.archive = archive;
                this.archive.src = 'https://player.vimeo.com/video/' + archive.vimeo_video_id;
                this.loadPlayer();

                return;
            }

            var urlEncoded = encodeURIComponent('https://player.vimeo.com/video/' + archive.vimeo_video_id)

            var url = 'https://vimeo.com/api/oembed.json?url=' + urlEncoded;

            if (duration > 0) {
                url = 'https://vimeo.com/api/oembed.json?url=' + urlEncoded + '&end_time=' + encodeURIComponent(duration) + '&watch_full_video=false';
            }
            try {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error(`Response status: ${response.status}`);
                }

                const json = await response.json();
                // 10% of duration
                var newDuration =  json.duration * (10/ 100);

                if (duration <= 0) {
                    await this.fetchFromVimeoApi(archive, newDuration);
                }

                if (this.archive.id === undefined) {
                    this.archive = archive;

                    const holder = $(json.html);
                    this.archive.src = holder.attr('src');
                    this.loadPlayer();
                }

            } catch (error) {
                console.error(error.message);
            }
        },
        loadPlayer() {
            $(() => {
                setTimeout(() => {
                    const iframe = document.querySelector('#vimeo-iframe');
                    const player = new Player(iframe);

                    player.on('play', () => {
                        this.showVideo = true;
                    });

                    player.on('ended', (data) => {
                        if (this.user.package !== null && this.user.package.is_default === 1) {
                            this.showVideo = false;
                        }
                    });
                }, 3000);
            });
        }
    }
}
</script>

<style scoped>

</style>
